import {all, call} from 'redux-saga/effects';
import clientsSaga from "./clientsSaga";
import errorSaga from "./errorSaga";
import betsSaga from "./betsSaga";
import playersSaga from "./playersSaga";
import transactionsSaga from "./transactionsSaga";
import activeBetsSaga from "./activeBetsSaga";

export default function* rootSaga() {
    yield all([
        call(clientsSaga),
        call(errorSaga),
        call(betsSaga),
        call(playersSaga),
        call(transactionsSaga),
        call(activeBetsSaga),
    ])
}
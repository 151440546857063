import React from "react";
import {Table, Skeleton} from "antd";

const TableLoader = ({count = 10, drag = true, icon = true, column = 1, actions= 0, pagination=true}) => {
    const elements=[];
    for(let i=0; i<count; i++) {
        elements.push({key: i})
    }

    const columns = [];
    if (drag) {
        columns.push({
            title: "",
            width: 40,
            className: 'noPadding',
            render: () => <div className="drag" />
        })
    }
    if (icon) {
        columns.push({
            title: <Skeleton active  paragraph={false} />,
            width: 60,
            className: 'noPadding',
            render: () =>  <Skeleton className="icon" active  paragraph={false} />
        })
    }

    for (let i = 0; i < column; i++) {
        columns.push({
            title: <Skeleton active  paragraph={false} />,
            width: 150,
            render: () =>  <Skeleton active  paragraph={false} />
        })
    }
    columns.push({
        render: () => ""
    })
    if (actions) {
        columns.push({
            title: <Skeleton active  paragraph={false} />,
            width: actions * 70,
            className: 'noPadding',
            render: () => <div className="actions">{ [...Array(actions).keys()].map((i) => <Skeleton.Button key={i} className="item" active  paragraph={false} />)}</div>
        })
    }
    return (
        <div className="tableLoader">
            <Table dataSource={elements} rowKey="key" columns={columns} pagination={false} scroll={{x: "100%"}}/>
            {
                pagination ?
                    <div className="tableFooterLoader">
                        <Skeleton.Button className="pageArrow" active  paragraph={false} />
                        <Skeleton.Button className="pageItem" active  paragraph={false} />
                        <Skeleton.Button className="pageDots" active  paragraph={false} />
                        <Skeleton.Button className="pageItemBig" active  paragraph={false} />
                        <Skeleton.Button className="pageItem" active  paragraph={false} />
                        <Skeleton.Button className="pageItem" active  paragraph={false} />
                        <Skeleton.Button className="pageDots" active  paragraph={false} />
                        <Skeleton.Button className="pageItem" active  paragraph={false} />
                        <Skeleton.Button className="pageArrow" active  paragraph={false} />
                        <Skeleton.Button className="pageText" active  paragraph={false} />
                        <Skeleton.Button className="pageDescription" active  paragraph={false} />
                        <Skeleton.Button className="pageOf" active  paragraph={false} />
                        <Skeleton.Button className="pageDescription" active  paragraph={false} />
                    </div> : ""
            }

        </div>
    )

}

export default TableLoader
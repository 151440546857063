import initialStore from "../initialStore";
import { SET_ALLOW_CSV_CANCEL } from "@actions/actionTypes";

export default function csvAllowCancelReducer(state = initialStore.allowCancel, action) {
    switch (action.type) {
        case SET_ALLOW_CSV_CANCEL:
            return {...state, ...action.allow};
        default:
            return state;
    }
}
import {createStore, applyMiddleware, compose} from "redux";
import rootReducer from "./reducers";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant"
import thunk from "redux-thunk";
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import {SET_ERROR_MSG} from "@actions/actionTypes";
import loadingMiddleware from "./middlewares/loadingMiddleware";
import uploadCsvMiddleware from "./middlewares/uploadCsvMiddleware"

const sagaMiddleware = createSagaMiddleware();


export default function configureStore(initialState) {
    const isDev = process.env.NODE_ENV === "development" || process.env.NODE_ENV === "env"
    const composeEnhancers =
        (isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ) || compose; // add support for Redux dev tools

    const middlewares = [
        thunk,
        sagaMiddleware,
        loadingMiddleware(),
        uploadCsvMiddleware()
    ];

    if (isDev) {
        middlewares.splice(1, 0, reduxImmutableStateInvariant());
    }

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    sagaMiddleware.run(rootSaga);

    const originalSetTimeout = window.setTimeout;

    window.setTimeout = function(fn, deley) {
        return originalSetTimeout( () => {
            try {
                fn()
            } catch (e) {
                store.dispatch({type: SET_ERROR_MSG, data: {type: 'error', messages: 'error_' + e}})
            }
        }, deley || 0);
    }

    return store;
}

export const DefaultLanguage = 'EN';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];

export const TransactionStatus = {
    1: "success",
    2: "pending",
    3: "failed",
    4: "timeout",
    5: "trying"
}

export const TransactionType = {
    0: "all",
    1: "bet",
    2: "win",
    3: "lose",
    4: "refund"
}

export const BetStatus = {
    1: "active",
    2: "win",
    3: "lose",
    4: "refund",
    "active": 1,
    "win": 2,
    "lose": 3,
    "refund": 4
}

export const GameType = {
    0: "public",
    1: "private",
}

export  const BetType = {
    1: "bet",
    2: "win",
    3: "lose",
    4: "refund"
}

export const  partnerResponseStatusCode = {
    0: "ok",
    1: "insufficient_balance",
    2: "timeout",
    3: "unexpected_error"
}

export const CsvProgresstype = {
    inprogress: 1,
    finished: 2,
    error: 3,
    cancelled: 4,
    emptyTable: 5
}

export const GameTemplateCurrency = [
    "USD",
    "GBP",
    "INR",
    "HKD",
    "AED",
];

export const PlayersCount = [2, 6]
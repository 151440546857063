import { isSetDataAndCsvUpload } from "../../utils/CommonUtils";

export default function uploadCsvMiddleware() {
    return ({dispatch}) => next => (action) => {
        if (typeof action === 'function')  return next(action);

        let setType = isSetDataAndCsvUpload(action.type);
        if (setType) {
            if (action.link) {
                window.open(`${action.link}`, "_blank");
            }
        }

        return next(action);
    };
}
import {SET_TRANSACTIONS_LIST} from "@actions/actionTypes";

export default function TransactionListReducer(state = [], action) {
    switch (action.type) {
        case SET_TRANSACTIONS_LIST:
            return action.transaction
        default:
            return state;
    }
}

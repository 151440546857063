import Clients from "../pages/contents/clients";
import Bets from "../pages/contents/bets";
import ActiveBets from "../pages/contents/activeBets";
import Transactions from "../pages/contents/transactions";
export default [
    {
        path: "/active_bets",
        component: ActiveBets
    },
    {
        path: "/clients",
        component: Clients
    },
    {
        path: "/bets",
        component: Bets
    },
    {
        path: "/transactions",
        component: Transactions
    }
]
import React from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    getPlayersByIp
} from "@actions/playersActions";


function PlayersByIp({label, isLoading, getPlayersByIp, selectPlayer, classN= ""}) {

    const click = () => {
        if (!isLoading) {
            getPlayersByIp(label)
        }
    }

    return (
        <div className={`link ${classN ? classN : ""}`} onClick={click}>
            {label}
        </div>
    )
}
function mapStateToProps(state) {
    return {
        isLoading: state.players.isLoading
    }
}

const mapDispatchToProps = {
    getPlayersByIp
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PlayersByIp));
import {SET_BET_LIST} from "@actions/actionTypes";

export default function BetsListReducer(state = [], action) {
    switch (action.type) {
        case SET_BET_LIST:
            return action.bets
        default:
            return state;
    }
}

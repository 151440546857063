import React from "react";
import {FormattedMessage} from "react-intl";
import {Pagination} from "antd";

export const TableProfit = ({profit, totalPages, handleTablePaginate, limit, page, intl}) => {
    return (
        <div className="tableFooter">
            <div className="profitList">
                <div className="list">
                    {
                        Object.keys(profit).length && Object.keys(profit).map(item =>
                            <div key={item} className="item">
                                <span className="title"><FormattedMessage id={item}/> - </span>
                                {profit[item]}
                            </div>
                        ) || ''
                    }
                </div>
            </div>
            <Pagination
                total={totalPages}
                showSizeChanger={true}
                showLessItems={true}
                onChange={(page, limit) => handleTablePaginate(page, limit)}
                showTotal={(total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`}
                defaultPageSize={limit}
                defaultCurrent={page}
                current={page}
            />
        </div>
    )
}

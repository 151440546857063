import React from "react";

const ModalLoader = ({tabs= false, lisItem = 5}) => {
    const elements=[];
    for(let i=0; i< lisItem; i++) {
        elements.push({key: i})
    }
    return (
        <div className="modalLoader">
            <div className="modalHeader">
                <div className="title"/>
                <div className="close"/>
            </div>
            {
                tabs ? <div className="tabs"/> : ""
            }
            <div className="modalContent">
                {
                    elements.map((item) =>(
                        <div className="modalLoaderCol listItem" key={item.key}>
                            <div className="label"/>
                            <div className="input">
                                <div className="value"/>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="modalFooter">
                <div className="button"/>
                <div className="button"/>
            </div>

        </div>
    )
}

export default ModalLoader;
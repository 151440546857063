import {SET_TRANSACTIONS_FILTER} from "@actions/actionTypes";
import initialStore from "../../initialStore";

export default function transactionsFilterReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_TRANSACTIONS_FILTER:
            return action.filter
        default:
            return state;
    }
}

import React from "react";
import classNames from "classnames";
import {Tooltip} from "antd";
import {FormattedMessage} from "react-intl";

const CsvDownloader = ({exportCVS, downloadLoading, cancelLoading, onCancel, allowCancel}) => {
    return (
        <button
            type="button"
            className={classNames("csvBtn", {"downloading": downloadLoading})}
            onClick={exportCVS}>
            {
                downloadLoading ?
                    <div className={classNames("cancelArea", {cancel: cancelLoading})} onClick={() => allowCancel ? onCancel() : null}>
                        <Tooltip title={allowCancel ? <FormattedMessage id={"cancel"}/> : undefined} color={"#fff"}>
                            <div className="loading">
                                {
                                    allowCancel ? <div className="icon"/> : ""
                                }

                                <div className="loader"/>
                            </div>
                        </Tooltip>
                        <FormattedMessage id='exporting'/>
                    </div> :
                    <>
                        <span className="downloadIcon">
                            <span className="arrow"/>
                            <span className="underline"/>
                        </span>
                        <FormattedMessage id='download_csv'/>
                    </>
            }
        </button>
    )
}

export default CsvDownloader
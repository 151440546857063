import initialStore from "../initialStore";
import {
    SET_USER,
    SET_IS_END_SILENT
} from "@actions/actionTypes";

export function userReducer(state = initialStore.user, action) {
    switch (action.type) {
        case SET_USER:
            return action.user || null;
        default:
            return state;
    }
}


export function isEndSilentRequestReducer(state = false, action) {
    switch (action.type) {
        case SET_USER:
            return true;
        case SET_IS_END_SILENT:
            return action.isEndSilentRequest
        default:
            return state;
    }
}
import React from "react";


function SearchHeader() {
    return (
        <div>
            <div>
                <i className="fi fi-rr-search"></i>
            </div>
        </div>
    )
}

export default SearchHeader;
import {UserManager, Log} from "oidc-client";
import {PutResource} from "../utils/HttpUtil";
import routes from "../routes/index";
import {isHavePermissionThisApp, getTheAppsUrlToByRedirected} from "../utils/ChackPermission";

Log.logger = console;
let path = '/';
if (window.location.pathname.indexOf('login') === -1 && window.location.pathname.indexOf('logout') === -1) {
    path = window.location.pathname + window.location.search;
    // console.error(window.location.pathname + window.location.search);
} else {
    // console.error('error', window.location.pathname + window.location.search);
}
if (path == '/') {
    path = routes[0].path;
}

export const manager = new UserManager({
    authority: process.env.REACT_APP_IDP_AUTHORITY,
    client_id: process.env.REACT_APP_IDP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_IDP_REDIRECT_LOGIN,
    response_type: "code",
    scope: "openid profile",
    loadUserInfo: true,
    response_mode: '',
    post_logout_redirect_uri: process.env.REACT_APP_IDP_REDIRECT_LOGOUT,
    automaticSilentRenew: true,
    silent_redirect_uri: process.env.REACT_APP_IDP_SILENT_LOGIN
});

let user = null;

function setUser(u) {
    if (u) {
        if (isHavePermissionThisApp(u)) {
            user = u;
        } else {
            const url = getTheAppsUrlToByRedirected(u);
            if (url) {
                window.location = url;
            } else {
                Logout();
            }
        }
    }
    user = u;
}

function getUser(u) {
    return user;
}

export function getPath() {
    return path;
}

export function GetToken() {
    return user ? user.access_token : null;
}

export async function LoadUser() {
    setUser(await manager.getUser())
    return user ? user : null;
}

export async function RedirectToLogin() {
    await manager.signinRedirect({data: path}).catch((error) => handleError(error));
}

export async function CompleteLogin() {
    return await manager
        .signinRedirectCallback()
        .then((u) => {
            setUser(u);
            return user ? user : null;
        })
        .catch((error) => handleError(error));
}

export async function Logout() {
    // window.location = `${process.env.CORE_URL}?redirectUri=${process.env.BONUS_SYSTEM_URL}&path=${path}`;
    await manager.signoutRedirect().catch((error) => handleError(error));
}

export async function CompleteLogout() {
    return await manager
        .signoutRedirectCallback()
        .then(() => {
            manager.removeUser();
        })
        .then(() => {
            setUser(null);
            RedirectToLogin();
        })
        .catch((error) => handleError(error));
}

function handleError(error) {
    if (error.message === "login_required") {
        Logout();
    } else if (window.message = "No matching state found in storage") {
        Logout();
    }
    console.error("Problem with authentication endpoint: ", error.message);
}

export async function SignUp() {
    const navigator = manager._redirectNavigator;
    const navigatorParams = {};
    return navigator.prepare(navigatorParams).then((handle) => {
        return manager
            .createSigninRequest(navigatorParams)
            .then((signinRequest) => {
                navigatorParams.url = signinRequest.url;
                navigatorParams.id = signinRequest.state.id;
                const returnUrl = navigatorParams.url.replace(
                    process.env.REACT_APP_IDP_AUTHORITY,
                    ""
                );
                navigatorParams.url =
                    process.env.REACT_APP_IDP_AUTHORITY +
                    "/Account/Register?ReturnUrl=" +
                    encodeURIComponent(returnUrl);
                return handle.navigate(navigatorParams);
            })
            .catch((err) => {
                if (handle.close) {
                    handle.close();
                }
                throw err;
            });
    });
}

export async function SigninSilent(user) {
    await manager.signinSilent().then((u) => {
        setUser(u);
    });
}

export async function UpdateProfile(user) {
    await PutResource("/profile", user);
    await SigninSilent();
}

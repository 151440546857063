import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    addClient,
    editClient
} from "@actions/clientsActions";
import {
    REQUEST_ADD_CLIENT,
    REQUEST_EDIT_CLIENT
} from "@actions/actionTypes";

import {Modal, Form, Input, Row, Col, Switch, Select, Button, Radio, Tabs, Space, Tooltip} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import {GameType, GameTemplateCurrency, PlayersCount} from "@constants/Enums";
import classNames from "classnames";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import Infinite from "react-infinite";
import iconDown from "@assets/img/icon/iconDown.svg";
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import USDIcon from "@assets/img/currency/USD.svg";
import GBPIcon from "@assets/img/currency/GBP.svg";
import INRIcon from "@assets/img/currency/INR.svg";
import HKDIcon from "@assets/img/currency/HKD.svg";
import AEDIcon from "@assets/img/currency/AED.svg";
import ModalLoader from "@components/Loaders/modalLoader";

const currencyIcons = {
    USD: USDIcon,
    GBP: GBPIcon,
    INR: INRIcon,
    HKD: HKDIcon,
    AED: AEDIcon
}

const defaultCurrencies = [
    {
        currency: undefined,
        name: undefined,
        decks: 2,
        playerCount: 2,
        pointValue: undefined

    }
]

function ClientControlPopup({handleCancel, show, intl, addClient, client, editClient, loading}) {
    const [form] = Form.useForm();
    const [tabMode, setTabMode] = useState("1");
    const [firstTabError, setFirstTabError] = useState(false);
    const [secondTabValid, setSecondTabValid] = useState(false);
    const [isFirstOpen, setIsFirstOpen] = useState(!client?.id)
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);

    useEffect(() => {
        if (client.id) {
            subscribeForError(REQUEST_EDIT_CLIENT, () => {
                handleCancel();
            });
        } else {
            subscribeForError(REQUEST_ADD_CLIENT, () => {
                handleCancel();
            });
        }
        return () => {
            unSubscribeForError(REQUEST_ADD_CLIENT);
            unSubscribeForError(REQUEST_EDIT_CLIENT);
        }
    }, [])

    useEffect(() => {
        if (client.id) {
            const data = {...client, availableModes: client.config.availableModes || [], ...client.config}
            data.availableModes = client.config.availableModes || []
            if (!(client.gameTemapletes && client.gameTemapletes[0])) {
                data.gameTemapletes = [...defaultCurrencies]
            }
            form.setFieldsValue(data)
        } else {
            form.setFieldsValue({gameTemapletes: defaultCurrencies})
        }
    }, [client])

    const onFinish = (data) => {
        const body = {config: {}, gameTemapletes: []}
        Object.keys(data).forEach(item => {
            if (item === "fee" || item === "onlyBots" || item === "isUnderMaintenance" || item === "availableModes") {
                body["config"][item] = data[item]
            } else {
                body[item] = data[item]
            }
        })
        if (client.id) {
            editClient({...body, id: client.id, name: client.name})
        } else {
            addClient(body)
        }
    }

    const handleModeChange = (e) => {
        const mode = e.target.value;
        if (mode === "2" || mode === "3") {
            setIsFirstOpen(false)
        }
        setTabMode(mode);
    }

    const formValueChange = () => {
        let firstTabValid = false;
        let secondTabValid = false;
        setFirstTabError(false);
        setSecondTabValid(false);


        form.getFieldsError().some((field) => {
            if (field.name.includes("gameTemapletes")) {
                secondTabValid = field.errors.length > 0
                return field.errors.length > 0
            } else {
                firstTabValid = field.errors.length > 0
                return field.errors.length > 0
            }
        })
        setFirstTabError(firstTabValid)
        setSecondTabValid(secondTabValid)
    }

    useEffect(() => {
        if (isFirstOpen) {
            return
        }
        const values = form.getFieldsValue();
        form.validateFields([...Object.keys(values)], {force: true}).then(() => formValueChange())
    }, [tabMode])

    const enterValidNumber = (value, name) => {
        const numberRegExp = new RegExp(/^(?:[1-9][0-9]*|0)$/);
        let formValue = form.getFieldsValue()
        if (numberRegExp.test(value)) {
            if (value != 0) {
                formValue[name[0]][name[1]][name[2]] = value

            } else {
                formValue[name[0]][name[1]][name[2]] = value.substring(0, value.length - 1)
            }
        } else {
            formValue[name[0]][name[1]][name[2]] = value.substring(0, value.length - 1)
        }

        form.setFieldsValue(formValue)
    }

    const enterValidDecimalNumber = (value, name) => {
        const numberRegExp = new RegExp(/^([0-9]+(\.?[0-9]?[0-9]?)?)$/g);
        let formValue = form.getFieldsValue()
        if (numberRegExp.test(value)) {
            formValue[name[0]][name[1]][name[2]] = value
            const afterDot = value.split(".")[0]
            const beforeDot = value.split(".")[1]
            if (/\b(0)+[1-9]\d*/g.test(afterDot)) {
                const val = afterDot.replace(/\b(0)+/g, '')
                formValue[name[0]][name[1]][name[2]] = val
            } else {
                if (/\b(0)+[.]\d*/g.test(value)) {
                    const val = afterDot.replace(/\b(0)+/g, '') + "." + beforeDot;
                    formValue[name[0]][name[1]][name[2]] = "0" + val
                }
            }
        } else {
            formValue[name[0]][name[1]][name[2]] = value.substring(0, value.length - 1)
        }

        form.setFieldsValue(formValue)
    }

    return (
        <Modal
            title={client.id ? <FormattedMessage id='edit_client'/> : <FormattedMessage id='add_client'/>}
            centered
            className={classNames(`formModal`, {loading: loading._single_client})}
            visible={show}
            width={596}
            onCancel={() => handleCancel()}
            footer={false}>
            {
                loading._single_client ?
                    <ModalLoader tabs={true}/> :
                    <>
                        <div className="tabMode centeredBox">
                            <Radio.Group onChange={handleModeChange} value={tabMode}
                                         className={classNames({invalid: firstTabError || secondTabValid})}>
                                <Radio.Button className={classNames("centeredBox", {invalid: firstTabError})} value="1"><FormattedMessage
                                    id={'details'}/></Radio.Button>
                                <Radio.Button className={classNames("centeredBox", {invalid: secondTabValid})}
                                              value="2"><FormattedMessage
                                    id={'game_templates'}/></Radio.Button>
                            </Radio.Group>
                        </div>
                        <Form
                            layout="vertical"
                            form={form}
                            style={{position: "relative"}}
                            name="trading-form"
                            onFinish={onFinish}
                            onFieldsChange={formValueChange}
                            scrollToFirstError
                            validateTrigger="onBlur"
                            wrapperCol={false}
                        >
                            <Tabs activeKey={tabMode} className="overflowContent">
                                <Tabs.TabPane key="1">
                                    <Row className="MainModal">
                                        <Col span={24} className="MainModal--parts">
                                            <div className="MainModal--parts--content">
                                                {client.id ?
                                                    <Form.Item label={<FormattedMessage id='name'/>}>
                                                        {client.name}
                                                    </Form.Item>
                                                    :
                                                    <Form.Item label={<FormattedMessage id='name'/>}
                                                               rules={[
                                                                   {
                                                                       required: true,
                                                                       message: <FormattedMessage
                                                                           id='name_validator'/>
                                                                   },
                                                                   ({getFieldValue}) => ({
                                                                       validator(_, value) {

                                                                           if (value && !(value.length >= 3 && value.length <= 256)) {
                                                                               return Promise.reject(intl.formatMessage({id: `name_valid`}))
                                                                           }

                                                                           return Promise.resolve();
                                                                       },
                                                                   })
                                                               ]}
                                                               name="name">
                                                        <Input autoComplete="off"
                                                               placeholder={intl.formatMessage({id: "type_name"})}/>
                                                    </Form.Item>
                                                }
                                                <Form.Item label={<FormattedMessage id='secret'/>}
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: <FormattedMessage
                                                                       id='secret_validator'/>
                                                               },
                                                               ({getFieldValue}) => ({
                                                                   validator(_, value) {

                                                                       if (value && value.length > 255) {
                                                                           return Promise.reject(intl.formatMessage({id: `client_secret_valid`}))

                                                                       }
                                                                       return Promise.resolve();
                                                                   },
                                                               })
                                                           ]}
                                                           name="secret"
                                                >
                                                    <Input autoComplete="off"
                                                           placeholder={intl.formatMessage({id: "type_secret"})}/>
                                                </Form.Item>
                                                <Form.Item label={<FormattedMessage id='bet_url'/>}
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: <FormattedMessage
                                                                       id='bet_url_validator'/>
                                                               },
                                                               ({getFieldValue}) => ({
                                                                   validator(_, value) {
                                                                       const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                       if (value && !urlPattern.test(value)) {
                                                                           return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                       }
                                                                       return Promise.resolve();
                                                                   },
                                                               })
                                                           ]}
                                                           name="betCallback"
                                                >
                                                    <Input autoComplete="off"
                                                           placeholder={intl.formatMessage({id: "type_bet_url"})}/>
                                                </Form.Item>
                                                <Form.Item label={<FormattedMessage id='result_url'/>}
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: <FormattedMessage
                                                                       id='result_url_validator'/>
                                                               },
                                                               ({getFieldValue}) => ({
                                                                   validator(_, value) {
                                                                       const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                       if (value && !urlPattern.test(value)) {
                                                                           return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                       }
                                                                       return Promise.resolve();
                                                                   },
                                                               })
                                                           ]}
                                                           name="resultCallback"
                                                >
                                                    <Input autoComplete="off"
                                                           placeholder={intl.formatMessage({id: "type_result_url"})}/>
                                                </Form.Item>
                                                <Form.Item label={<FormattedMessage id='rollback_url'/>}
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: <FormattedMessage
                                                                       id='rollback_url_validator'/>
                                                               },
                                                               ({getFieldValue}) => ({
                                                                   validator(_, value) {
                                                                       const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                       if (value && !urlPattern.test(value)) {
                                                                           return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                       }
                                                                       return Promise.resolve();
                                                                   },
                                                               })
                                                           ]}
                                                           name="refundCallback"
                                                >
                                                    <Input autoComplete="off"
                                                           placeholder={intl.formatMessage({id: "type_rollback_url"})}/>
                                                </Form.Item>
                                                <Form.Item label={<FormattedMessage id='balance_url'/>}
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: <FormattedMessage
                                                                       id='balance_url_validator'/>
                                                               },
                                                               ({getFieldValue}) => ({
                                                                   validator(_, value) {
                                                                       const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                       if (value && !urlPattern.test(value)) {
                                                                           return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                       }
                                                                       return Promise.resolve();
                                                                   },
                                                               })
                                                           ]}
                                                           name="balanceCallback"
                                                >
                                                    <Input autoComplete="off"
                                                           placeholder={intl.formatMessage({id: "type_balance_url"})}/>
                                                </Form.Item>
                                                <Form.Item label={<FormattedMessage id="available_modes"/>}
                                                           name="availableModes"
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: <FormattedMessage
                                                                       id='available_modes_validator'/>
                                                               }]}>
                                                    <Select mode="multiple"
                                                            getPopupContainer={triggerNode => triggerNode.parentElement}
                                                            optionLabelProp="label"
                                                            showArrow
                                                            showSearch={false}
                                                            suffixIcon={<img src={iconDown}/>}>
                                                        {
                                                            Object.keys(GameType).map(key =>
                                                                <Select.Option key={+key} value={+key}
                                                                               label={<FormattedMessage
                                                                                   id={GameType[+key]}/>}>
                                                                    <FormattedMessage id={GameType[+key]}/>
                                                                </Select.Option>
                                                            )
                                                        }
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    label={<FormattedMessage id='only_bots'/>}
                                                    className="formHorizontal"
                                                    name="onlyBots"
                                                    valuePropName="checked">
                                                    <Switch className="radioSwitch"/>
                                                </Form.Item>
                                                <Form.Item label={<FormattedMessage id='fee'/>}
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: <FormattedMessage id='fee_validator'/>
                                                               },
                                                               ({getFieldValue}) => ({
                                                                   validator(_, value) {
                                                                       const urlPattern = new RegExp(/^(?:[1-9][0-9]*|0)$/)
                                                                       if (value !== "" && !urlPattern.test(value)) {
                                                                           return Promise.reject(intl.formatMessage({id: `fee_value_is_not_integer`}));
                                                                       } else if (value > 100 || value < 0) {
                                                                           return Promise.reject(intl.formatMessage({id: `fee_value_is_not_valid`}));
                                                                       }
                                                                       return Promise.resolve();
                                                                   },
                                                               })
                                                           ]}
                                                           name="fee"
                                                >
                                                    <Input autoComplete="off" style={{width: '100%'}}
                                                           placeholder={intl.formatMessage({id: "type_fee"})}/>
                                                </Form.Item>
                                                <Form.Item
                                                    label={<FormattedMessage id='is_under_maintenance'/>}
                                                    className="formHorizontal"
                                                    name="isUnderMaintenance"
                                                    valuePropName="checked">
                                                    <Switch className="radioSwitch"/>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane key="2">
                                    <Form.List name="gameTemapletes">
                                        {(fields, {add, remove}) => {
                                            return <>
                                                <Infinite containerHeight={400}
                                                          elementHeight={fields.map((_, index) => index === 0 ? 75 : 48)}>
                                                    {fields.map(({key, name, fieldKey, ...restField}) =>
                                                        <Space key={key} className={`defaultGames ${name === 0 ? "firstChild" : ""}`}>
                                                            <Form.Item
                                                                className="without-star"
                                                                label={name === 0 ?
                                                                    <FormattedMessage id='currency_name'/> : undefined}
                                                                {...restField}
                                                                name={[name, 'currency']}
                                                                fieldKey={[fieldKey, 'currency']}
                                                                rules={
                                                                    [
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage
                                                                                id='currency_is_required'/>
                                                                        }
                                                                    ]
                                                                }
                                                            >
                                                                <Select placeholder={<FormattedMessage id="select"/>}
                                                                        optionLabelProp="label"
                                                                        dropdownMatchSelectWidth={false}
                                                                        className="gameTemplateCurrencySelect"
                                                                        getPopupContainer={triggerNode => triggerNode.parentElement}>
                                                                    {
                                                                        GameTemplateCurrency.map(currency => (
                                                                            <Select.Option value={currency}
                                                                                           key={currency}
                                                                                           label={
                                                                                               <>
                                                                                                   <img
                                                                                                       src={currencyIcons[currency]}
                                                                                                       className="icon"/>
                                                                                                   <FormattedMessage
                                                                                                       id={`${currency}_short`}/>
                                                                                               </>
                                                                                           }>
                                                                                <img
                                                                                    src={currencyIcons[currency]}
                                                                                    className="icon"/>
                                                                                <FormattedMessage
                                                                                    id={currency}/>
                                                                            </Select.Option>
                                                                        ))
                                                                    }

                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                className="without-star"
                                                                label={name === 0 ?
                                                                    <FormattedMessage id='name'/> : undefined}
                                                                name={[name, 'name']}
                                                                fieldKey={[fieldKey, 'name']}
                                                                rules={
                                                                    [
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage
                                                                                id='name_is_required'/>
                                                                        },
                                                                        ({getFieldValue}) => ({
                                                                            validator(_, value) {
                                                                                const nameRegExp = new RegExp(/^[\w|_| ]+$/)

                                                                                if (value && !(value.length >= 3 && value.length <= 256)) {
                                                                                    return Promise.reject(intl.formatMessage({id: `name_valid`}))
                                                                                }
                                                                                if (value && !nameRegExp.test(value)) {
                                                                                    return Promise.reject(intl.formatMessage({id: `name_must_not_contain_special_characters`}))
                                                                                }

                                                                                return Promise.resolve();
                                                                            },
                                                                        })
                                                                    ]
                                                                }
                                                            >
                                                                <Input autoComplete="off"
                                                                       placeholder={intl.formatMessage({id: "type_name"})}/>
                                                            </Form.Item>
                                                            <Form.Item
                                                                className="without-star"
                                                                label={name === 0 ?
                                                                    <FormattedMessage id='decks'/> : undefined}
                                                                {...restField}
                                                                initialValue={2}
                                                                name={[name, 'decks']}
                                                                fieldKey={[fieldKey, 'decks']}
                                                                rules={
                                                                    [
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage
                                                                                id='decks_is_required'/>
                                                                        }
                                                                    ]
                                                                }
                                                            >
                                                                <Input autoComplete="off"
                                                                       onChange={({target}) => enterValidNumber(target.value, ["gameTemapletes", name, 'decks'])}
                                                                       placeholder={intl.formatMessage({id: "type_decks"})}/>
                                                            </Form.Item>
                                                            <Form.Item
                                                                className="without-star"
                                                                {...restField}
                                                                label={name === 0 ?
                                                                    <FormattedMessage id='player_count'/> : undefined}
                                                                name={[name, 'playerCount']}
                                                                initialValue={2}
                                                                fieldKey={[fieldKey, 'playerCount']}
                                                                rules={
                                                                    [
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage
                                                                                id='player_count_is_required'/>
                                                                        }
                                                                    ]
                                                                }
                                                            >
                                                                <Select
                                                                    placeholder={intl.formatMessage({id: "type_player_count"})}
                                                                    optionLabelProp="label"
                                                                    dropdownMatchSelectWidth={false}
                                                                    autoComplete="off"
                                                                    getPopupContainer={triggerNode => triggerNode.parentElement}>
                                                                    {PlayersCount.map(item => (
                                                                        <Select.Option value={item}
                                                                                       key={item}
                                                                                       label={item}>
                                                                            {item}
                                                                        </Select.Option>))}
                                                                </Select>
                                                            </Form.Item>

                                                            <Form.Item
                                                                className="without-star"
                                                                {...restField}
                                                                label={name === 0 ?
                                                                    <FormattedMessage id='point_value'/> : undefined}
                                                                name={[name, 'pointValue']}
                                                                fieldKey={[fieldKey, 'pointValue']}
                                                                rules={
                                                                    [
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage
                                                                                id='point_value_is_required'/>
                                                                        },
                                                                        ({getFieldValue}) => ({
                                                                            validator(_, value) {

                                                                                if (value == 0) {
                                                                                    return Promise.reject(intl.formatMessage({id: `point_value_must_be_number`}));
                                                                                }

                                                                                return Promise.resolve();
                                                                            },
                                                                        })

                                                                    ]
                                                                }
                                                            >
                                                                <Input autoComplete="off"
                                                                       onChange={({target}) => enterValidDecimalNumber(target.value, ["gameTemapletes", name, 'pointValue'])}
                                                                       placeholder={intl.formatMessage({id: "type_point_value"})}/>
                                                            </Form.Item>
                                                            <MinusOutlined
                                                                className={classNames("iconBox ", {disabled: fields.length === 1})}
                                                                onClick={() => {
                                                                    remove(name)
                                                                    setTimeout(() => formValueChange(), 10)
                                                                }}
                                                                disabled={fields.length === 1}/>
                                                        </Space>
                                                    )}
                                                </Infinite>
                                                <Form.Item className="add-button">
                                                    <Tooltip placement="left"
                                                             title={<FormattedMessage id="add_currency"/>}>
                                                        <PlusOutlined
                                                            onClick={() => add()}/>
                                                    </Tooltip>
                                                </Form.Item>
                                            </>
                                        }}
                                    </Form.List>
                                </Tabs.TabPane>
                            </Tabs>
                            <Form.Item className="MainModal--footer">
                                <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                    <FormattedMessage id='cancel'/>
                                </button>
                                <Button type="primary"
                                        htmlType="submit"
                                        style={{height: 36}}
                                        className={classNames("miniBtn", {submit: firstTabError || secondTabValid})}
                                        loading={loading._add_client || loading._edit_client}
                                >
                                    <FormattedMessage id='save'/>
                                </Button>
                                <div className="error_box"><FormattedMessage id="form_error"/></div>
                            </Form.Item>
                        </Form>
                    </>
            }
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading
    }
}

const mapDispatchToProps =
    {
        addClient,
        editClient
    }

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ClientControlPopup));
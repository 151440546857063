import React from "react";
import {List} from "antd";

export default function LoadingList ({count}) {

    return <List
                size="small"
                className="loading-list"
                header={null}
                footer={null}
                bordered
                dataSource={[... new Array(count)].map((item, i) => ({key: i, item}))}
                renderItem={item => <List.Item className="loadin-item"><div></div></List.Item>}
            />
    
}
export const FETCH_FAILED = "FETCH_FAILED";

export const USER_LOGGING_REQUEST = "USER_LOGGING_REQUEST";
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const SET_IS_END_SILENT = "SET_IS_END_SILENT";


// SET
export const SET_LANG_LIST = "SET_LANG_LIST";


// clients

//GET
export const REQUEST_CLIENT_LIST = "REQUEST_CLIENT_LIST"
export const REQUEST_SINGLE_CLIENT = "REQUEST_SINGLE_CLIENT"

//SET
export const SET_CLIENT_LIST = "SET_CLIENT_LIST"
export const SET_EDIT_CLIENT = "SET_EDIT_CLIENT"
export const SET_ADD_CLIENT = "SET_ADD_CLIENT"
export const SET_SINGLE_CLIENT = "SET_SINGLE_CLIENT"

//POST
export const REQUEST_ADD_CLIENT = "REQUEST_ADD_CLIENT"

//PUT
export  const REQUEST_EDIT_CLIENT = "REQUEST_EDIT_CLIENT"

export const REQUEST_START_PATH = "REQUEST_START_PATH";
export const SET_START_PATH = "SET_START_PATH";

//errors
export const  SET_ERROR_MSG = "SET_ERROR_MSG"
export const  RESET_ERROR_MSG = "RESET_ERROR_MSG"
export const CLEAR_RESPONSE_ERROR = "CLEAR_RESPONSE_ERROR"
export const CHANGE_RESPONSE_ERROR = "CHANGE_RESPONSE_ERROR"

//loading

export const CHANGE_LOADING = 'CHANGE_LOADING';

// bets

// players
export const REQUEST_PLAYERS_BY_IP = "REQUEST_PLAYERS_BY_IP"
export const SET_PLAYERS_BY_IP = "SET_PLAYERS_BY_IP"
export const RESET_PLAYERS_BY_IP = "RESET_PLAYERS_BY_IP"

//GET
export const REQUEST_BET_LIST = "REQUEST_BET_LIST"
export const REQUEST_BET_LIST_FILTER = "REQUEST_BET_LIST_FILTER"
export const REQUEST_BET_CSV_DOWNLOADER_URL = "REQUEST_BET_CSV_DOWNLOADER_URL"


//SET
export const SET_BET_LIST = "SET_BET_LIST"
export const SET_BET_LIST_FILTER = "SET_BET_LIST_FILTER"
export const SET_BET_CSV_DOWNLOADER_URL = "SET_BET_CSV_DOWNLOADER_URL"

//PUT

export const REQUEST_CANCEL_BET_CSV_DOWNLOAD = "REQUEST_CANCEL_BET_CSV_DOWNLOAD"
export const SET_CANCEL_BET_CSV_DOWNLOAD = "SET_CANCEL_BET_CSV_DOWNLOAD"

// transactions

//GET
export const REQUEST_TRANSACTIONS_FILTER = "REQUEST_TRANSACTIONS_FILTER"
export const REQUEST_TRANSACTIONS_LIST = "REQUEST_TRANSACTIONS_LIST"
export const REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL = "REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL"

//SET
export const SET_TRANSACTIONS_FILTER = "SET_TRANSACTIONS_FILTER"
export const SET_TRANSACTIONS_LIST = "SET_TRANSACTIONS_LIST"
export const SET_TRANSACTIONS_CSV_DOWNLOADER_URL = "SET_TRANSACTIONS_CSV_DOWNLOADER_URL"

//PUT
export const REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD = "REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD"
export const SET_CANCEL_TRANSACTIONS_CSV_DOWNLOAD = "SET_CANCEL_TRANSACTIONS_CSV_DOWNLOAD"

// activeBets

//GET
export const REQUEST_ACTIVE_BET_LIST = "REQUEST_ACTIVE_BET_LIST"

//SET
export const SET_ACTIVE_BET_LIST = "SET_ACTIVE_BET_LIST"
export const CLEAR_ACTIVE_BET_LIST = "CLEAR_ACTIVE_BET_LIST"

// csv
export const SET_ALLOW_CSV_CANCEL = "SET_ALLOW_CSV_CANCEL"

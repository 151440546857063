import {CsvProgresstype} from "@constants/Enums";
import {GetResource} from "../utils/HttpUtil";
import {setErrorMsg} from "@actions/alertAction";

let timeOut = {}

function checkCsvProgress(obj) {
    return (new Promise((resolve, reject) => {
        try {
            fetchApi(obj);

            function fetchApi(requestObj) {
                const csvFilesProgress =  window.CsvProcess.getStore() || [];
                const stopedProcess = csvFilesProgress.filter(item => item.canceled)[0];
                const process = window.CsvProcess.getProcessIdByAction(requestObj.actionType)

                if (process !== requestObj.processId || stopedProcess) {
                    delete timeOut[requestObj.actionType]
                    resolve("")
                } else {
                    GetResource(`${requestObj.checkProcessUrl}?key=${requestObj.processId}`)
                        .then((res) => {
                            if (res.progressCode == CsvProgresstype.inprogress) {
                                timeOut[requestObj.actionType] = setTimeout(() => {
                                    fetchApi(requestObj);
                                }, 5000);
                            } else if (res.progressCode == CsvProgresstype.emptyTable) {
                                window.CsvProcess.removeProcess(requestObj.processId)
                                setTimeout(() => { reject("55") }, 0 )
                            } else if (res.progressCode == CsvProgresstype.error) {
                                window.CsvProcess.removeProcess(requestObj.processId)
                                setTimeout(() => {reject( "56")}, 0 )
                            } else if (res.progressCode == CsvProgresstype.cancelled) {
                                window.CsvProcess.removeProcess(requestObj.processId);
                                resolve('')
                            } else {
                                if (res && res.url) {
                                    window.CsvProcess.processHasLink(requestObj.actionType)
                                    resolve(res.url);
                                } else {
                                    resolve('');
                                }

                            }
                        }).catch((err) => {
                        reject(err);
                    })
                }
            }
        }catch (err){
            reject(err)
        }
    }))
}

export const waitForFile = (actionType, processId, checkProcessUrl) => {
    return new Promise((resolve, reject) => {
        checkCsvProgress({
            actionType,
            processId,
            checkProcessUrl
        }).then((url) => {
            resolve(url);
        }).catch((err) => {
            setErrorMsg(err)
            reject(err);
            if (navigator.onLine) {
                window.CsvProcess.removeProcess(processId)
            }
        })
    })
}


export function startCsvExport(dataList) {
    return dispatch => {
        dataList.forEach(element => {
            dispatch({
                type: element.actionType,
                processId: element.processId
            })
        });
    }
}
import initialStore from "../../initialStore";
import {
    SET_SINGLE_CLIENT,
} from "@actions/actionTypes";

export default function singleClient(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_SINGLE_CLIENT:
            return  action.client
        default:
            return state;
    }
}

import React, {useEffect} from 'react'
import {connect} from "react-redux";
import { Button } from 'antd';
import {FormattedMessage} from "react-intl";
import {goToLoginForm} from '@actions/userActions';
import {loginUser, setUser, loadUser} from '@actions/userActions';

function Login({goToLoginForm, user, isEndSilentRequest, loadUser}) {

    // useEffect(() => {
    //     if (isEndSilentRequest) {
            
    //     }
    // }, [isEndSilentRequest]);

    useEffect(() => {
        if (!user) {
          loadUser();
        }
      }, [])
    
    return (
        ''
    )
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isEndSilentRequest: state.isEndSilentRequest
    }
}

const mapDispatchToProps = {
    goToLoginForm,
    loadUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
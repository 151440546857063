import React, {useEffect} from 'react'
import {connect} from "react-redux";
import {removeUser} from '@actions/userActions';

function CallbackLogout({removeUser}) {

    useEffect(() => {
        removeUser();
        CompleteLogout();
    }, [removeUser]);

    return (
        <div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

const mapDispatchToProps = {
    removeUser
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackLogout);
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlString, getUrlValue} from "../../../utils/CommonUtils";
import TableLoader from "@components/Loaders/tableLoader";
import {Table} from "antd";
import {getBetList} from "@actions/betsActions";
import moment from "moment-timezone";
import classNames from "classnames";
import BetsFilter from "@components/BetsFilter/BetsFilter";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import {copyLabel, getFormattedBody} from "@services/TableServices";
import {TableProfit} from "@components/TableProfit/TableProfit";
const keysOfDate = ["dateFrom", "dateTo"]
import PlayersByIp from "@components/PlayersByIp/PlayersByIp";
import PlayersListPopup from "@components/PlayersListPopup/PlayersListPopup";
import {BetStatus} from "@constants/Enums"


function Bets({intl, loading, getBetList, bets, setErrorMsg, setDoneMsg}) {
    let history = useHistory()
    let search = history && history.location && history.location.search

    const [totalPages, setTotalPages] = useState(0)
    const [limit, setLimit] = useState(+getUrlValue(search).limit || 10)
    const [page, setPage] = useState(+getUrlValue(search).page || 1)
    const [profit, setProfit] = useState({})
    const [showFilter, setShowFilter] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState("")
    const columns = [
        {
            title: intl.formatMessage({id: "client"}),
            dataIndex: 'client',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetStatus[record.status] + "_bg"}`}>{record.client}</div>
            }
        },
        {
            title: intl.formatMessage({id: "player"}),
            dataIndex: 'player',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetStatus[record.status] + "_bg"}`}>{record.player}</div>
            }
        },
        {
            title: intl.formatMessage({id: "date"}),
            dataIndex: 'date',
            width: "fit-content",
            render: (text, record) => {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                return <div className={`${BetStatus[record.status] + "_bg"}`}>{moment(record.date).tz(timeZone).format("DD/MM/YYYY  HH:mm:ss")}</div>
            }

        },
        {
            title: intl.formatMessage({id: "amount"}),
            dataIndex: 'amount',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetStatus[record.status] + "_bg"}`}>{record.amount}</div>
            }
        },
        {
            title: intl.formatMessage({id: "status"}),
            dataIndex: 'status',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`${BetStatus[record.status] + "_bg"} ${BetStatus[record.status]}`}><FormattedMessage
                    id={BetStatus[record.status]}/></div>
            }

        },
        {
            title: intl.formatMessage({id: "session"}),
            dataIndex: 'session',
            width: "fit-content",
            render: (text, record) => {
                return <div className={`copyable ${BetStatus[record.status] + "_bg"}`}
                            onDoubleClick={(e) => {
                                copyLabel(e, record.session)
                                    .then(() => {
                                        setDoneMsg("text_is_copied")
                                    }).catch(() => {
                                    setErrorMsg("something_wrong_the_text_is_not_copied")
                                })
                            }}>{record.session}</div>
            }
        },
        {
            title: intl.formatMessage({id: "ip"}),
            dataIndex: 'ip',
            width: "fit-content",
            render: (text, record) => {
                return  <PlayersByIp label={record.ip} classN={`${BetStatus[record.status] + "_bg"}`}/>
            }
        },
        // {
        //     title: intl.formatMessage({id: "profit"}),
        //     dataIndex: 'profit',
        //     width: "fit-content",
        //     render: (text, record) => {
        //         return <div className={`${BetStatus[record.status] + "_bg"}`}>{record.profit} </div>
        //     }
        // }
    ];


    const fetchBets = (isFirst) => {
        let initialForm = getUrlValue(search, keysOfDate)
        initialForm = {
            ...initialForm,
            page : initialForm.page || page,
            limit: initialForm.limit || limit,
            playerNameLike: initialForm.playerNameLike || ""
        }
        if (isFirst) {
            const {params} = getUrlString(initialForm)
            if (Array.from(params).length === 2 && params.has("page") && params.has("limit") && params.get("page") == 1 && params.get("limit") == 10) {
                initialForm = {
                    ...initialForm,
                    date:{
                        start: moment(new Date().setDate(new Date().getDate() - 3)).endOf('day').utc().format(),
                        end: moment(new Date()).utc().format()
                    },
                }
            }

        }
        if(Object.keys(initialForm).length) {
            getBetList(initialForm)
            const {params} = getUrlString(initialForm)
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }

    useEffect(() => {
        fetchBets(true)
    }, [])

    useEffect(() => {
        return history.listen((location, action) => {
            const loc = location.search
            if (loc) {
                const initialForm = getUrlValue(loc, keysOfDate)
                getBetList(getFormattedBody(initialForm))
                setPage(+initialForm.page || page)
                setLimit(+initialForm.limit || limit)
            }
        })
    },[history])

    useEffect(() => {
        if (selectedPlayer) {
            let initialForm = getUrlValue(search, keysOfDate);
            initialForm = getFormattedBody(initialForm)
            const {params} = getUrlString({
                clientId: initialForm.clientId,
                page: 1,
                limit,
                playerNameLike: selectedPlayer
            })

            setPage(1)
            history.push({search: params.toString()})
            setSelectedPlayer("")
        }

    }, [selectedPlayer])


    useEffect(() => {
        if (bets && bets.items) {
            setTotalPages(bets.total);
            setProfit(bets.profit || {});
        }
    }, [bets]);

    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;
        if (limit === changedLimit) newPage = changedPage
        setPage(newPage);
        setLimit(changedLimit)
        const initialForm = getUrlValue(search, keysOfDate)
        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit})
        history.push({search: params.toString()})

    }

    return (
        <div className="content bets">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='bets'/></div>
                <button className={classNames("filterBtn", {show: showFilter})} onClick={() => setShowFilter(prev => !prev)}>
                    <div className="icon"/>
                    <FormattedMessage id='filter'/>
                </button>
            </div>
            <BetsFilter limit={limit}
                        setPage={setPage}
                        showFilter={showFilter}
                        selectedPlayer={selectedPlayer}
            />
            <div className="bets">
                <div className="mainTable">
                    {loading._bet_list ? <TableLoader count={limit} column={8} icon={false} drag={false} actions={0}/> :
                        <div>
                        <Table dataSource={bets.items || []}
                               columns={columns}
                               rowKey="date"
                               rowClassName={(record) => `${BetStatus[record.status] + "_bg"}`}
                               scroll={{x: '100%'}}
                               pagination={false}
                        />
                            <TableProfit profit={profit}
                                         totalPages={totalPages}
                                         handleTablePaginate={handleTablePaginate}
                                         limit={limit}
                                         page={page}
                                         intl={intl}/>
                        </div>
                    }
                </div>
                <PlayersListPopup selectPlayer={setSelectedPlayer}/>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        bets: state.betsList,
        loading: state.loading
    }
}

const mapDispatchToProps = {
    getBetList,
    setErrorMsg,
    setDoneMsg
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Bets));
import {LoadUser, Logout, CompleteLogout, RedirectToLogin, SigninSilent, getPath} from "@services/AuthService";
import {
    EXTERNAL_LOGIN_SUCCESS,
    SET_LOGIN_ERROR,
    SET_REGISTRATION_ERROR,
    SET_USER,
    SHOW_LOGIN,
    SHOW_REGISTRATION,
    USER_LOGGING_OUT,
    SET_IS_END_SILENT,
    USER_LOGGING_REQUEST,
    SET_START_PATH,
    REQUEST_START_PATH
} from "@actions/actionTypes";

export function loginUser(body) {
    return dispatch => {
         dispatch({
            type: USER_LOGGING_REQUEST,
            body
        })
    }
}

export function setUser(user) {
    return dispatch => {
        dispatch({type: SET_USER, user});
    }
}

export function logoutUser() {
    return dispatch => {
        Logout();
    }
}

export function goToLoginForm() {
    return dispatch => {
        RedirectToLogin();
    }
}

export function removeUser() {
    return dispatch => {
        CompleteLogout().then(() => {
            dispatch({type: SET_USER, user: null});
        })
    }
}

export function loadUser() {
    return async (dispatch) => {
        return LoadUser().then((user) => {
            console.error("load")
            console.log(user)
            if (user) {
                let path = getPath();
                dispatch({type: SET_START_PATH, path})
                dispatch({type: SET_USER, user});
            } else {
                dispatch({type: SET_IS_END_SILENT, isEndSilentRequest: true});
                SigninSilent().then(() => {
                    LoadUser().then((user) => {
                        if (user) {
                            dispatch({type: SET_USER, user});
                        } else {
                            RedirectToLogin();
                        }
                        dispatch({type: SET_IS_END_SILENT, isEndSilentRequest: true});
                    })
                }).catch((e) => {
                    RedirectToLogin();
                    dispatch({type: SET_IS_END_SILENT, isEndSilentRequest: true});
                });
            }
        });
    };
}
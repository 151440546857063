import {combineReducers} from "redux";
import settings from "./settingsReducer";
import {
    userReducer as user,
    isEndSilentRequestReducer as isEndSilentRequest
 } from "./userReducer";
import langList from "./langListReducer";
import clientsList from "./clients/clientsListReducer";
import error from "./errorReducer";
import betsList from "./bets/betListReducer";
import betsListFilter from "./bets/betListFilterReducer";
import transactionsFilter from "./transactions/transactionsFilterReducer";
import transactionsList from "./transactions/transactionListReducer";
import players from "./playersReducer";
import activeBetsList from "./activeBets/activeBetsListReducer";
import loading from "./loadingReducer";
import responseErrors from "./responseErrorsReducer";
import path from "./pathReducer";
import singleClient from "./clients/singleClientReducer";
import csvAllowCancel from "./csvAllowCancelReducer";

const rootReducer = combineReducers({
    settings,
    user,
    isEndSilentRequest,
    langList,
    clientsList,
    error,
    betsList,
    betsListFilter,
    players,
    transactionsFilter,
    transactionsList,
    activeBetsList,
    loading,
    responseErrors,
    path,
    singleClient,
    csvAllowCancel
});

export default rootReducer;

import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_ADD_CLIENT,
    REQUEST_CLIENT_LIST,
    SET_CLIENT_LIST,
    REQUEST_EDIT_CLIENT,
    SET_EDIT_CLIENT,
    SET_ERROR_MSG,
    SET_ADD_CLIENT,
    REQUEST_SINGLE_CLIENT,
    SET_SINGLE_CLIENT
} from "@actions/actionTypes";
import {GetResource, PostResource, PutResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";

let prevPage = "", prevLimit = "", prevNameLike = "", prevSortDirDesc = "";

function* fetchClients({limit, page, nameLike, sortDirDesc}) {
    page && (prevPage = page);
    limit && (prevLimit = limit);
    nameLike ? prevNameLike = nameLike  : prevNameLike = "";
    sortDirDesc !== "" && (prevSortDirDesc = sortDirDesc);
    const clients = yield call(GetResource, `/clients?page=${page}&limit=${limit}&clientNameLike=${encodeURIComponent(nameLike)}&sortDirDesc=${sortDirDesc}`);
    yield put({ type: SET_CLIENT_LIST, clients})
}

function* addClient({body}) {
    const client = yield call(PostResource, `/clients`,body);
    yield put({
        type: REQUEST_CLIENT_LIST,
        limit: prevLimit,
        page: prevPage,
        nameLike: encodeURIComponent(prevNameLike),
        sortDirDesc: prevSortDirDesc
    })
    yield put({type: SET_ADD_CLIENT, client});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
}

function* editClient({body}) {
    const client =  yield call(PutResource, `/clients/${body.id}`,body);
    yield put({
        type: SET_EDIT_CLIENT,
        client: client.data
    });
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
}

function* getSingleClient({id}) {
    const client =  yield call(GetResource, `/clients/${id}`);
    yield put({
        type: SET_SINGLE_CLIENT,
        client
    });
}

function* clientsSaga() {
    yield takeLatest(REQUEST_CLIENT_LIST,safe(onError, fetchClients));
    yield takeLatest(REQUEST_ADD_CLIENT, safe(onError, addClient));
    yield takeLatest(REQUEST_EDIT_CLIENT, safe(onError, editClient));
    yield takeLatest(REQUEST_SINGLE_CLIENT, safe(onError, getSingleClient));
}

export default clientsSaga;